<template>
    <b-card>
        <b-row>
            <div class="col-12 mt-16">
                <b-form @submit.prevent="addUser" v-if="show">
                    <!-- <h2>Car Information</h2> -->
                    <div style="
              background-color: #0010f7;
              height: 40px;
              border-radius: 4px;
            ">
                        <h5       style="
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  padding-top : 8px;
                ">
                            Add Expense
                        </h5>
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Select Driver:" label-for="user_id">
                                <b-form-select id="user_id" v-model="user_id" required>
                                    <option value="">Select Driver</option>
                                    <option value="39">Driver Loop</option>
                                    <option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                                        {{ driver.name }} &nbsp;{{ driver.last_name }} &nbsp; &nbsp; | &nbsp; &nbsp; {{
                                            driver.id }}

                                    </option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Amount:" label-for="amount">
                                <b-form-input id="amount" v-model="amount" placeholder="Enter  amount"
                                type="number"
                                    required></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Amount Type:" label-for="amount_type">
                                <b-form-select id="amount_type" v-model="amount_type" required>
                                    <option value="">Select Type</option>
                                    <option>Inclusive Amount</option>
                                    <option>Exclusive Amount</option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Category:" label-for="category">
                                <b-form-select id="category" v-model="category" required>
                                    <option value="">Select Category</option>
                                    <option>Petrol</option>
                                    <option>Puncher</option>
                                    <option>Fuel</option>
                                    <option>Air Pressure</option>
                                    <option>Tyre Change</option>
                                    <option>Challan</option>
                                    <option>Online Payment</option>
                                    <option>Customer</option>
                                    <option>Oil Change</option>
                                    <option>Other</option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-4 col-12" v-if="category === 'Fuel'">
               
               <b-form-group
                 id="input-group-2"
                 label="Select Fuel Card:"
                 label-for="fuel_card"
               >
                 <b-form-select
                   id="fuel_card"
                   placeholder="Enter select fuel card"
                   v-model="fuel_card"
                   required
                 >
                   <!-- options are coming from database fuel_card -->
                   <option value="">Select Fuel Card</option>
                   <!-- selected will be showns -->
                   <option
                     v-for="fuel_card in fuel_cards"
                     :key="fuel_card.id"
                     :value="fuel_card.id"
                   >
                     {{ fuel_card.card_number }} | {{ fuel_card.name }} {{ fuel_card.last_name }}
                   </option>
                 </b-form-select>
                
               </b-form-group>
             </div>
                        <div v-if="category == 'Other'" class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Enter Expense Detail:" label-for="amount">
                                <b-form-input id="amount" v-model="category_details" placeholder="Enter Expense Detail"
                                    required></b-form-input>
                            </b-form-group>
                        </div> 

                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Payment Type:" label-for="card">
                                <b-form-select id="card" v-model="card" required>
                                    <option value="">Select Payment Type</option>
                                    <option>Payable</option>
                                    <option>Receivable</option>
                                    <option>Normal</option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Document Upload:" label-for="profile_picture">
                                <div style="margin-left: 3px; margin-bottom: 15px">
                                    <input type="file" accept="image/*" id="image[]" @change="onProfilePictureChange" />
                                </div>
                            </b-form-group>
                        </div>
                        <div v-if="card === 'Normal'" class="col-md-4 col-12">
                <b-form-group label="Commnent" label-for="fix-salary-input">
                  <b-form-input id="comment" type="text" v-model="comment"
                    placeholder="Enter comment"></b-form-input>
                </b-form-group>
              </div>
                    </div>
                    <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                        <span v-if="!isLoading">Submit</span>
                        <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                    </b-button>
                </b-form>
            </div>
            <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                <pre v-highlightjs>
          <code class="hljs html">
            {{ codeText }}
          </code>
        </pre>
            </div>
        </b-row>
    </b-card>
</template>


<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
}
    from "bootstrap-vue";
import axios from "axios";
import code from "../components/data-entry/form/code";
import { BToast } from "bootstrap-vue";
export default {
    data() {
        return {


            user_id: '',
            drivers: [], // Your list of drivers
            filteredDrivers: [],
            isDropdownOpen: false,

            filterText: '',
            selectedType: "",
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            image: null,
            isLoading: false,
            amount: '',
            category: '',
            card: '',
            user_id: '',
            drivers: [],
            comment:'',
            amount_type: '',
            category_details: '',
            fuel_card: '',
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast,
        BSpinner,
    },
    // computed: {
    //     filteredDrivers() {
    //         // Filter drivers based on the search term
    //         return this.drivers.filter((driver) =>
    //             driver.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    //         );
    //     },
    // },

    created() {
        // Load the clients data when the component is created
        axios
            .get("ApproedDriver")
            .then((response) => {
                this.drivers = response.data.data;
            })
            .catch((error) => {
                console.log(error);
            });

            axios
      .get("allfuelcards")
      .then((response) => {
        this.fuel_cards = response.data.data;
        // filter whose fuel_cards.driver_id == null
        // this.fuel_cards = this.fuel_cards.filter(
        //   (fuel_card) => fuel_card.driver_id == null
        // );
      })
      .catch((error) => {
        console.log(error);
      });
    },
    methods: {
        // search able  code 
        // filterDrivers() {
        //     // Implement filtering logic based on user input
        //     const searchText = this.user_id.toLowerCase();
        //     this.filteredDrivers = this.drivers.filter((driver) =>
        //         driver.name.toLowerCase().includes(searchText)
        //     );
        //     this.isDropdownOpen = true; // Show the dropdown
        // },
        // selectDriver(driver) {
        //     // Implement the selection logic here
        //     this.user_id = driver.name; // Set the input field value to the selected driver's name
        //     this.isDropdownOpen = false; // Hide the dropdown
        // },

        // search able  code end
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
        onReset(event) {
            event.preventDefault();
            // Reset our form values
            this.form.email = "";
            this.form.name = "";
            this.form.food = null;
            this.form.checked = [];
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        //    Add Vehicle
        addUser() {
            this.isLoading = true;
            // Create a FormData object to handle the image file
            const formData = new FormData();
            formData.append("image", this.image);
            formData.append("amount", this.amount);
            formData.append("category", this.category);
            formData.append("card", this.card);
            formData.append("user_id", this.user_id);
            formData.append("comment", this.comment);
            formData.append("amount_type", this.amount_type); 
            formData.append("category_details", this.category_details);
            axios
                .post("expense", formData)
                .then((response) => {
                    console.log(response.data);
                    this.$bvToast.toast("Expense added successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", // Background color
                    });
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    console.log(error.response.data);
                    this.isLoading = false;
                });
        },
        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.image = file;
            }
        },

        codeClick() {
            this.codeActive = !this.codeActive;

            setTimeout(() => {
                this.codeActiveClass = !this.codeActiveClass;
            }, 100);
        },

        vehicleImageChange(event) {
            const files = event.target.files;
            if (files && files.length > 0) {
                // Convert FileList to an array
                this.image = Array.from(files);
            }
        },

    },
};
</script>

<!-- <style scoped>
/* Style for the searchable dropdown container */
.searchable-dropdown {
    position: relative;
}

/* Style for the search input */
.search-input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    /* Add space for the search icon */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Style for the search icon */
.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

/* Style for the dropdown content */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1;
}

/* Style for dropdown items */
.dropdown-content li {
    padding: 12px 16px;
    list-style-type: none;
    cursor: pointer;
}

/* Highlight the selected item */
.dropdown-content li:hover {
    background-color: #ddd;
}

/* Show the dropdown when it's open */
.dropdown-content.show {
    display: block;
}
</style> -->



